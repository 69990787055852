import App from "./App.svelte";

let targets = document.getElementsByClassName("price-slider-wrapper");
for (let target of targets) {
  const app = new App({
    target,
    props: {},
  });
}

// export default app;

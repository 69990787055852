<script>
  import Slider from "@bulatdashiev/svelte-slider";
  const MIN = 100000;
  const MAX = 2000000;

  let url = new URL(window.location.href);
  let minprice = url.searchParams.get("minprice");
  if (minprice) minprice = minprice.replace(new RegExp("\\.", "g"), "");
  let maxprice = url.searchParams.get("maxprice");
  if (maxprice) maxprice = maxprice.replace(new RegExp("\\.", "g"), "");

  let value = [minprice || MIN, maxprice || MAX];

  $: formattedMin = value[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let formattedMax;
  $: {
    if (value[1] >= MAX) {
      formattedMax = `${value[1]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}+`;
    } else {
      formattedMax = value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }

  function minKeyup(args) {
    const newValue = parseFloat(args.target.value.replace(/[^0-9]/g, ""));
    value[0] = newValue;
  }
  function maxKeyup(args) {
    const newValue = parseFloat(args.target.value.replace(/[^0-9]/g, ""));
    value[1] = newValue;
  }
</script>

<style>
  :global(:root) {
    --track-bg: #ebebeb;
    --progress-bg: #fe6f00;
    --thumb-bg: #fe6f00;
  }
</style>

<div class="row">
  <div class="col-sm-12 col-xs-12 price_div">
    <div class="van">
      <span class="search_title" for="dlst_van">Van €</span>
      <input class="t2_price-input" value={formattedMin} on:keyup={minKeyup} />
      <input type="hidden" name="minprice" value={value[0]} />
    </div>
  </div>
  <div class="col-sm-12 col-xs-12 price_div">
    <div class="van">
      <span class="search_title" for="dlst_van">Tot €</span>
      <input
        class="t2_price-input"
        value={formattedMax}
        on:keyup={maxKeyup}
        placeholder="Geen maximum" />
      <input
        type="hidden"
        name="maxprice"
        value={value[1] < MAX ? value[1] : ''} />
    </div>
  </div>
</div>
<Slider min={MIN} max={MAX} step="100000" bind:value range order />
